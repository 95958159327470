import axios from "axios";
import { baseURL, getAccessToken } from "../../config/setting";

const UploadService ={
    getAttachments(requestId, token = null, authId = null) {
        let url = `${baseURL}signatures/${requestId}/attachments/`;
        if (token && authId) {
            url += `?authId=${authId}`;
        }

        return new Promise((resolve) => {
            const response = axios.get(url, {
                headers: {
                    Authorization: `Token ${token}`,
                    Accept: "application/json",
                }
            })

            resolve(response)
        })
    },

    uploadAttachments(requestId, files, token = null, authId = null) {
        console.log("requestId - ", requestId);
        console.log("files - ", files);
        console.log("token - ", token);
        console.log("authId - ", authId);

        let url = `${baseURL}signatures/${requestId}/attachments/`
        if (token && authId) {
            url += `?authId=${authId}`;
        }

        return new Promise((resolve) => {
            const fileArray = Array.from(files)
            fileArray.map(file => {
                const formData = new FormData()
                formData.append('file', file)

                const response = axios.post(url, formData, {
                    headers: {
                    Authorization: `Token ${token}`,
                        'Content-Type': "multipart/form-data; boundary=<calculated when request is sent>",
                        Accept: "application/json",
                    },
                })
    
                resolve(response)
            })
        })
    },
    
    removeAttachments(requestId, attachmentId, token = null, authId = null) {
        let url = `${baseURL}signatures/${requestId}/attachment/${attachmentId}/`
        let accessToken = getAccessToken();
        if (token && authId) {
            url += `?authId=${authId}`;
            accessToken = token;
        }

        return new Promise((resolve) => {
            const response = axios.delete(url, {
                headers: {
                    Authorization: `Token ${accessToken}`,
                    Accept: "application/json",
                },
            })

            resolve(response)
        })
    },

    downloadAttachments(requestId, attachmentId, token = null, authId = null) {
        let url = `${baseURL}signatures/${requestId}/attachment/${attachmentId}/`
        let accessToken = getAccessToken();
        if (token && authId) {
            url += `?authId=${authId}`;
            accessToken = token;
        }

        return new Promise((resolve) => {
            const response = axios.get(url, {
                headers: {
                    Authorization: `Token ${accessToken}`,
                    responseType: 'arraybuffer'
                },
            })

            resolve(response)
        })
    }
};

export default UploadService;