<template>
	<div>
		<v-overlay v-model="dialogVisibility" color="#0E82B9" opacity="0.92">
			<v-container>
				<v-row class="pa-10 text-center">
					<div v-if="error" class="col-sm-1 col-md-3"></div>
					<div v-if="error" class="col-sm-10 col-md-6">
						<v-alert class="mt-5 red" border="left" text>
							{{ errorMsg }}
						</v-alert>
					</div>
					<div class="col-12 white--text">
						{{ $t("please_tell_us_your_ID_to_sign.", { id: typeOfId }) }}
					</div>
					<div class="offset-md-4 col-md-4 col-sm-12">
						<input type="text" id="custom-input" v-model="citizenId" :placeholder="$t('_id')"
							@input="citizenId = $event.target.value.toUpperCase()" class="txt-uppercase" v-uppercase />
					</div>
					<div class="col-12">
						<v-btn color="#F36E21" @click="confirmAuthID()" depressed class="mt-3" min-width="96">
							{{ $t("ok") }}
						</v-btn>
					</div>
				</v-row>
			</v-container>
		</v-overlay>
	</div>
</template>

<script>
import { SignaturesService } from "../../../services/multiple/signatures-service";
import UploadService from '../../../services/multiple/upload-service.js'

export default {
	name: "access-modal",

	props: ["dialog", "documentId", "documentToken", "typeOfId"],

	data: function () {
		return {
			citizenId: null,
			error: false,
			errorMsg: "",
			loading: false,
			dialogVisibility: false,
		};
	},

	watch: {
		dialog() {
			this.dialogVisibility = this.dialog
		}
	},

	computed: {
		requestId() {
			return this.documentId;
		},
		isAuthCitizenId() {
			return this.$route.query.auth && this.$route.query.auth == 'citizenid' ? true : false;
		},
		hasAssociation() { //to check if the url comes with the company name
			return this.$store.getters['common/hasAssociation']
		},
		docToken() {
			return this.documentToken;
		}
	},

	methods: {

		async getUploadedDocCount($request_id,$request_token,$request_auth_id) {
        const attachments = await UploadService.getAttachments($request_id, $request_token , $request_auth_id);
        return attachments.data.length;
    },
		confirmAuthID() {
			this.error = false;
			this.errorMsg = "";
			if (this.citizenId) {
				SignaturesService.getSignatureRequestDataAuth(
					this.requestId,
					this.citizenId,
					this.isAuthCitizenId,
					this.docToken
				)
					.then((response) => {
						this.error = false;
						this.loading = false;
						if (
							response.data.signerId &&
							response.data.signerId.id == this.citizenId
						) {
							this.$store
								.dispatch("common/addAuthId", this.citizenId)
								.then((response) => {
									if (response) {
										this.$emit("closeModal");
										this.checkForNextStep();

									}
								});
						} else {
							this.error = true;
							this.errorMsg = this.$t("invalid_auth_id");
						}
					})
					.catch((error) => {
						this.loading = false;
						if (error.response.status == 403) {
							this.error = true;
							this.errorMsg = error.response.data.description;
						}
					});
			}
		},
		async checkForNextStep() {

			let documentCheckList = localStorage.getItem("documentCheckList") ? JSON.parse(localStorage.getItem("documentCheckList")) : [];

			// Update the document checklist after all status updates are completed
			documentCheckList.forEach((document) => {
				document.checkId = true;
				document.idChecked = true;
				document.isMultiple = true;
			});

			localStorage.setItem('documentCheckList', JSON.stringify(documentCheckList));


			let documentCheckListUpdated = localStorage.getItem("documentCheckList") ? JSON.parse(localStorage.getItem("documentCheckList")) : [];
			// Using a for...of loop to handle async/await
			for (const [index, document] of documentCheckListUpdated.entries()) {
				console.log(index);
				await this.updateDocumentStatus(document, this.citizenId); // Await ensures each update completes before continuing
				console.log(document.checkAttachments);
				console.log(document.attachmentsChecked);
			}


			let documentCheckListFinal = localStorage.getItem("documentCheckList") ? JSON.parse(localStorage.getItem("documentCheckList")) : [];
			// Check for next steps after updating statuses
			for (const document of documentCheckListFinal) {
				let docCount = await this.getUploadedDocCount(document.id, document.apiToken, document.signerId.id);
        docCount = Number(docCount);

        if (isNaN(docCount)) {
            console.error('docCount is not a number:', docCount);
            docCount = 0;  // Set default to 0 if NaN
        }

				if (document.checkAttachments && !document.attachmentsChecked && (!(docCount > 0))) {
					this.$router.push({
						name: `${this.hasAssociation ? 'custom-' : ''}multiple-attachments`,
						params: { id: document.documentId },
						query: {
							...this.$route.query,
							token: document.documentToken,
							auth_id: this.citizenId,
							document_name: document.documentName
						}
					});

					break;
				} else if (!document.checkAttachments && !document.checkSignerForm && ((docCount > 0))) {
					this.$router.push({
						name: `${this.hasAssociation ? 'custom-' : ''}multiple-pdf`,
						params: { id: document.documentId },
						query: {
							...this.$route.query,
							token: document.documentToken,
							auth_id: this.citizenId,
							document_name: document.documentName
						}
					});
				} else if (!document.checkAttachments && document.checkSignerForm && (!document.signerFormChecked) && (!(docCount > 0))) {
					this.$router.push({
						name: `${this.hasAssociation ? 'custom-' : ''}multiple-forms`,
						params: { id: document.documentId },
						query: {
							...this.$route.query,
							token: document.documentToken,
							auth_id: this.citizenId,
							document_name: document.documentName
						}
					});
				}
			}
		},



		//update document attachments and form status
		async updateDocumentStatus(document) {

			let documentRequestID = document.documentId;
			let currentDocumentToken = document.documentToken;

			try {
				const response = await SignaturesService.getSignatureRequestDataAuth(
					documentRequestID,
					this.citizenId,
					this.isAuthCitizenId,
					currentDocumentToken
				);
				this.error = false;
				this.loading = false;
				let documentCheckList = localStorage.getItem("documentCheckList") ? JSON.parse(localStorage.getItem("documentCheckList")) : [];
				documentCheckList.forEach((document) => {
					if (document.documentId == response.data.id) {
						document.hasAttachments = response.data.hasAttachments;
						document.checkAttachments = response.data.hasAttachments,
						document.hasPendingSignerForm = response.data.hasPendingSignerForm;
						document.hasSignerForm = response.data.hasSignerForm;
						document.checkSignerForm = response.data.hasSignerForm;
						document.auth_id = response.data.signerId.id;
					}
				});

				console.log(documentCheckList);
				localStorage.setItem('documentCheckList', JSON.stringify(documentCheckList));


			} catch (error) {
				this.loading = false;
				if (error.response.status == 403) {
					this.error = true;
					this.errorMsg = error.response.data.description;
				}
			}
		}
	},
};
</script>
